// dummy.ts
import { CheckboxOptions } from './checkbox-options.interface'; // Adjust the path as necessary
import { GrowthStageOptions } from './growth-stage-options.interface';
import { InteriorOptions } from './interior-options.interface';
import { PotOptions } from './pot-options.interface';
import { RadioOptions } from './radio-options.interface';
import { SelectionOptions } from './selection-options.interface';

export const DUMMY_CHECKBOX_OPTIONS: CheckboxOptions[] = [
  { id: 1, label: 'Lorem ipsum dolor' },
  { id: 2, label: 'Lorem ipsum dolor' },
  { id: 3, label: 'Lorem ipsum dolor' },
  { id: 4, label: 'Lorem ipsum dolor' }
];


export const DUMMY_RADIO_OPTIONS: RadioOptions[] = [
  { id: 1, label: 'Answer one' },
  { id: 2, label: 'Answer two' },
  { id: 3, label: 'Answer three' }
];

// Dummy data for pot options
export const DUMMY_POT_OPTIONS: PotOptions[] = [
  { id: 1, label: 'En pot 1' },
  { id: 2, label: 'En pot 2' },
  { id: 3, label: 'En pot 3' },
];

// Dummy data for growth stage options
export const DUMMY_GROWTH_STAGE_OPTIONS: GrowthStageOptions[] = [
  { id: 1, label: 'Semis 1' },
  { id: 2, label: 'Semis 2' },
  { id: 3, label: 'Semis 3' },
];

// Dummy data for selection options
export const DUMMY_SELECTION_OPTIONS: SelectionOptions[] = [
  { id: 1, label: 'Sélectionner 1' },
  { id: 2, label: 'Sélectionner 2' },
  { id: 3, label: 'Sélectionner 3' },
];

// Dummy data for Intérieur options
export const DUMMY_INTERIOR_OPTIONS: InteriorOptions[] = [
  { id: 1, label: 'Intérieur 1' },
  { id: 2, label: 'Intérieur 2' },
  { id: 3, label: 'Intérieur 3' },
];

// Dummy data for QUESTIONS
export const QUESTIONS_MOCK = [
  {
    id: 1,
    question: "What is your favorite vegetable?",
    questionType: "selectMany",
    options: ["Carrot", "Potato", "Tomato"],
    optionLength: 1,
    actionButton: false,
  },
  {
    id: 2,
    question: "What is your favorite session food?",
    questionType: "radio",
    options: ["Carrot", "Potato", "Tomato", "Broccoli"],
    optionLength: 4,
    actionButton: false,
  },
  {
    id: 3,
    question: "Select all colors you like",
    questionType: "checkbox",
    options: ["Red", "Green", "Blue", "Yellow"],
    optionLength: 4,
    actionButton: true,
  },
  {
    id: 4,
    question: "Enter three keywords to describe yourself",
    questionType: "form",
    length: 3,
    actionButton: true,
  },
  {
    id: 5,
    question: "Do you prefer indoor or outdoor plants?",
    questionType: "boolean",
    options: ["Yes", "No"],
    actionButton: false,
  },
  {
    id: 6,
    question: "Rate your gardening skill",
    questionType: "range",
    range: { min: 1, max: 5 },
    actionButton: true,
  },
  {
    id: 7,
    question: "A quoi ressemble des sliders ?",
    actionButton: true,
  }
];